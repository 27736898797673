<template>
    <!-- 订单取消处理中 -->
    <div class="order_cancel_handle_box">
        <!-- 上半部分 -->
        <div class="order_cancel_handle_top">
            <!-- 标题 & 时间 -->
            <div class="order_cancel_handle_top_title">
                <!-- 标题 -->
                <span>请等待平台处理</span>
                <!-- 时间 -->
                <span><i class="el-icon-time"></i>还剩2天59分59秒</span>
            </div>
            <!-- 小标题 -->
            <div class="order_cancel_handle_top_subtitle">由于某些原因订单无法生成，金额将在三个工作日内原路退还</div>
        </div>
        <!-- 分割线 -->
        <hr>
        <!-- 下半部分 -->
        <div class="order_cancel_handle_bottom">
            <div class="order_cancel_handle_bottom_title">退款详情</div>
            <!-- 产品 -->
            <div>
                <span class="order_cancel_handle_bottom_img">
                    <img :src="require('@/assets/images/lensman/582592b3c2b7d.jpg_400x400.jpg')" alt="">
                </span>
                <span>婚纱拍摄</span>
            </div>
            <!-- 拍摄费用 -->
            <div>
                <span class="order_cancel_handle_bottom_subtitle">拍摄费用：</span>
                <span class="order_cancel_handle_bottom_content">¥599.00</span>
            </div>
            <!-- 场地费用 -->
            <div>
                <span class="order_cancel_handle_bottom_subtitle">场地费用：</span>
                <span class="order_cancel_handle_bottom_content">¥90.00</span>
            </div>
            <!-- 退款编号 -->
            <div>
                <span class="order_cancel_handle_bottom_subtitle">退款编号：</span>
                <span class="order_cancel_handle_bottom_content">109768213</span>
            </div>
            <!-- 退款金额 -->
            <div>
                <span class="order_cancel_handle_bottom_subtitle">退款金额：</span>
                <span class="order_cancel_handle_bottom_content">¥689.00</span>
            </div>
            <!-- 取消原因 -->
            <div>
                <span class="order_cancel_handle_bottom_subtitle">取消原因：</span>
                <span class="order_cancel_handle_bottom_content">订单失效</span>
            </div>
            <!-- 取消说明 -->
            <div>
                <span class="order_cancel_handle_bottom_subtitle">取消说明：</span>
                <span class="order_cancel_handle_bottom_content"></span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    
}
</script>

<style scoped>
/* 订单取消等待处理盒子 */
.order_cancel_handle_box{
    padding: 30px;
    border: 1px solid #DCDFE6;
}

/* 上半部分标题 */
.order_cancel_handle_top_title{
    margin-bottom: 20px;
}
/* 标题 */
.order_cancel_handle_top_title>span:first-child{
    margin-right: 20px;
    font-size: 20px;
    font-weight: bold;
}
/* 时间 */
.order_cancel_handle_top_title>span:last-child{
    color: #E6A23C;
}
/* 副标题 */
.order_cancel_handle_top_subtitle{
    color: #909399;
}

.order_cancel_handle_bottom>div{
    margin-bottom: 10px;
}
/* 图片 */
.order_cancel_handle_bottom_img{
    display: inline-block;
    vertical-align: middle;
    width: 60px;
    height: 60px;
    margin-right: 10px;
    border: 1px solid #DCDFE6;
    overflow: hidden;
}
.order_cancel_handle_bottom_img>img{
    max-width: 100%;
}
/* 下半部分标题 */
.order_cancel_handle_bottom_title{
    font-size: 16px;
}
/* 小标题 */
.order_cancel_handle_bottom_subtitle{
    color: #C0C4CC;
}
</style>
